import React, { useState, useMemo, useEffect } from 'react'
import type { MarketGateProps } from './MarketGate.types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useAppContext } from 'context/AppContext/AppContext'
import RequestStatus from 'components/RequestStatus'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle, Box } from '@mui/material'
import type { Market } from 'types/Market'
import { useSearchParams } from 'react-router-dom'
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined'
import { readQueryParams } from 'utils/generateQueryParams'
import { selectedMarketName } from 'config/globalVariables'

const MarketGate: <T>(props: MarketGateProps<T>) => JSX.Element = ({ Component, componentProps, onlyFlow, titleTranslationCode, allOption = false }) => {
  const { t } = useTranslation()
  const { markets, isMarketsLoading } = useAppContext()
  const [selectedMarketId, setSelectedMarketId] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()

  const filterMarkets = (market: Market): boolean => {
    if (onlyFlow !== undefined && onlyFlow === 'gwp') {
      return market.flow === 'gwp'
    }
    if (onlyFlow !== undefined && onlyFlow === 'pd') {
      return market.flow === 'pd'
    }
    return true
  }

  useEffect(() => {
    if (markets !== undefined && !('error' in markets) && markets.data.length > 0) {
      setSelectedMarketId(markets.data.filter(filterMarkets)?.[0]?.id.toString() ?? '')
    } else {
      setSelectedMarketId('')
    }
  }, [titleTranslationCode, markets])

  useEffect(() => {
    const paramsMarketId = searchParams.get('marketId')
    if (markets !== undefined && !('error' in markets)) {
      if (paramsMarketId !== undefined && paramsMarketId !== null) {
        const marketExist = markets.data.some(market => market.id.toString() === paramsMarketId)
        if (marketExist || paramsMarketId === '-1') {
          setSelectedMarketId(paramsMarketId)
        } else {
          setSelectedMarketId('')
        }
        return
      }
      const marketFromLS = localStorage.getItem(selectedMarketName)
      if (marketFromLS) {
        const marketExist = markets.data.some(market => market.id.toString() === marketFromLS)
        if (marketExist) {
          handleChangeMarketId(marketFromLS)
        }
      }
    }
  }, [searchParams, titleTranslationCode, markets])

  const handleChangeMarketId = (value: string): void => {
    if (localStorage) {
      localStorage.setItem(selectedMarketName, value)
    }
    setSelectedMarketId(value)
    setSearchParams(prev => ({ ...readQueryParams(prev), marketId: value }))
  }

  const selectedMarket = useMemo(() => {
    if (markets === undefined || 'error' in markets) {
      return undefined
    }

    return markets.data.filter(filterMarkets).find(el => el.id.toString() === selectedMarketId.toString())
  }, [selectedMarketId, markets])

  if (markets === undefined || 'error' in markets) {
    return <RequestStatus data={markets} isLoading={isMarketsLoading} />
  }

  return <>
    <Box display='flex' justifyContent='space-between'>
      <Typography variant='h4' component='h1' mb={2}>{t(titleTranslationCode)}</Typography>
      <FormControl sx={{ width: 300 }}>
        <InputLabel id="market-label">{t('common.selectMarket')}</InputLabel>
        <Select
          labelId="market-label"
          id="market-select"
          value={selectedMarketId}
          label={t('common.selectMarket')}
          onChange={(e) => { handleChangeMarketId(e.target.value) }}
          MenuProps={{ sx: { maxHeight: 285 } }}
        >
          {markets.data.filter(filterMarkets).sort((a, b) => a.name.localeCompare(b.name)).map(market => (
            <MenuItem key={market.code} value={market.id}>
              {market.name}
            </MenuItem>
          ))}
          {allOption !== undefined && allOption
            ? (
              <MenuItem value='-1'>
                {t('common.all')}
              </MenuItem>)
            : null}
        </Select>
      </FormControl>
    </Box>
    {selectedMarket !== undefined || selectedMarketId !== ''
      ? <div>
        <Component market={selectedMarket} selectedMarketId={selectedMarketId} {...componentProps} />
      </div>
      : <Alert severity="error" icon={<FolderOffOutlinedIcon />}>
        <AlertTitle>{t('dialogs.marketNotFound')}</AlertTitle>
      </Alert>}
  </>
}

export default MarketGate
